import React, { useEffect, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/BT Icon Logo.svg'
import Logout from '../../images/logout.svg'
import Placeholder from '../../images/placeholder.png'
import { useAuth } from './../../context/AuthContext'
import { LOGOUT_URL } from '../../api'
import { allUserPaths } from '../UserPermissions/UserPermissions'

export default function Sidebar() {
  const searchRef = useRef()
  const { currentUser, superUser, currentUserPathPermissions } = useAuth()

  function initSidebar() {
    if (!window.$) {
      setTimeout(initSidebar)
      return
    }
    const $ = window.$
    $('[data-widget="treeview"]').Treeview('init')
    $('[data-widget="sidebar-search"]').SidebarSearch('init')
  }

  const sideNavs = useMemo(() => {
    const finalSideNavs = []

    for (let skey in currentUserPathPermissions) {
      const sideSubNavs = []
      for (let subkey in currentUserPathPermissions[skey]) {
        if (currentUserPathPermissions[skey][subkey] === true || superUser === true) {
          const { text, link } = allUserPaths[skey]['subpaths'][subkey]
          sideSubNavs.push(
            <li key={subkey} className="nav-item">
              <Link to={link} className="nav-link">
                <i className="far fa-circle nav-icon" style={{ fontSize: '15px' }}></i>
                <p style={{ fontSize: '15px' }}>{text}</p>
              </Link>
            </li>
          )
        }
      }

      if (sideSubNavs.length > 0) {
        const { text, link, icon } = allUserPaths[skey]
        finalSideNavs.push(
          <li key={skey} className="nav-item">
            <Link to={link} className="nav-link menu">
              <img
                src={icon}
                alt=""
                style={{
                  marginRight: '10px',
                  marginBottom: '2px',
                  width: '23px',
                  height: '23px',
                }}
              />
              <p>
                {text}
                <i className="right fas fa-angle-left"></i>
              </p>
            </Link>
            <ul className="nav nav-treeview">{sideSubNavs}</ul>
          </li>
        )
      }
    }

    return finalSideNavs
  }, [superUser, currentUserPathPermissions])

  useEffect(() => {
    initSidebar()
  }, [sideNavs])

  return (
    <>
      <nav
        className="main-header navbar navbar-expand navbar-white navbar-light fixed-top"
        style={{ paddingTop: '0', paddingBottom: '0' }}
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button">
              <i className="fas fa-bars"></i>
            </a>
          </li>
          <li className="nav-item d-sm-inline-block">
            <Link to="/home" className="nav-link" id="navhome">
              Home
            </Link>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <div className="d-flex justify-content-center align-items-center">
              <div className="image" style={{ padding: '10px' }}>
                <img
                  src={currentUser?.extra_data?.picture || Placeholder}
                  className="img-circle elevation-2"
                  alt="User Image"
                  style={{ height: '30px', width: '30px' }}
                />
              </div>
              <div className="info">
                <a className="nav-link" data-toggle="dropdown" href="#" style={{ paddingLeft: '0' }}>
                  {currentUser?.extra_data?.name || 'FloData Analytics'}
                </a>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                  style={{
                    padding: '5px 16px',
                    minWidth: '180px',
                  }}
                >
                  {superUser ? (
                    <Link
                      to="/home/user-permissions"
                      style={{
                        display: 'flex',
                        gap: '18px',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: '#000',
                        margin: '8px 0',
                      }}
                    >
                      <i className="fas fa-user-secret" style={{ color: 'rgba(0,0,0,0.6)' }}></i>
                      <h3 className="dropdown-item-title">User permissions</h3>
                    </Link>
                  ) : null}

                  <a
                    href={LOGOUT_URL}
                    style={{
                      display: 'flex',
                      gap: '18px',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: '#000',
                      margin: '8px 0',
                    }}
                  >
                    <img src={Logout} style={{ height: '15px', width: '15px' }} />
                    <h3 className="dropdown-item-title">Logout</h3>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ position: 'fixed' }}>
        <Link
          to="/home"
          className="brand-link"
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
          }}
        >
          <img
            src={Logo}
            alt="AdminLTE Logo"
            className="brand-image"
            style={{
              marginLeft: '0.5rem',
              opacity: '.9',
            }}
          />
          <div
            className="brand-text font-weight-dark"
            style={{
              fontSize: ' 22px',
              fontWeight: 'bold',
              marginBottom: '-.5rem',
              marginLeft: '-.2rem',
              marginTop: '0.1rem',
              fontFamily: 'Bahnschrift',
            }}
          >
            BETTER TRUCKS
          </div>
        </Link>

        <div className="sidebar" style={{ overflow: 'auto' }}>
          <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search" style={{ marginTop: '.5rem' }}>
              <input
                className="form-control form-control-sidebar"
                type="search"
                ref={searchRef}
                placeholder="Search"
                aria-label="Search"
              />
              <div className="input-group-append">
                <button className="btn btn-sidebar" onClick={() => (searchRef.current.value = '')}>
                  <i className="fas fa-search fa-fw"></i>
                </button>
              </div>
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {sideNavs}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  )
}
