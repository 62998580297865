import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from './components/Home/Home'
import AuthProvider from './context/AuthContext'
import Login from './pages/login'
import loadable from '@loadable/component'
const Adfile = loadable(() => import('./components/Adfile/Adfile'))
const Rts = loadable(() => import('./components/Rts/Rts'))
const UserPermissions = loadable(() => import('./components/UserPermissions/UserPermissions'))

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<PrivateRoute />} />
        <Route path="login" element={<Login />} />
        <Route path="home" element={<Home />}>
          <Route
            index
            element={
              <div className="content-wrapper" style={{ marginTop: '50px', height: 'calc(100vh - 108px)' }}>
                {/* <h1>Home Root</h1> */}
              </div>
            }
          />
          <Route path="adfile" element={<Adfile />} />
          {/* <Route path="rts" element={<Rts />} /> */}
          <Route path="user-permissions" element={<UserPermissions />} />
          <Route
            path="*"
            element={
              <div className="content-wrapper" style={{ marginTop: '50px', height: 'calc(100vh - 108px)' }}></div>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  )
}
