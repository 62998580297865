import React, { useContext, useState, useLayoutEffect } from 'react'
import { fetchLoggedIn, fetchUser, userDataToPathPermissions } from '../api'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [currentUserPathPermissions, setCurrentUserPathPermissions] = useState(null)
  const [superUser, setSuperUser] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)

  async function init() {
    const [{ LoggedIn, SuperUser }, { user, userPathPermissions }] = await Promise.all([fetchLoggedIn(), fetchUser()])
    setCurrentUser(user)
    setLoggedIn(LoggedIn)
    setSuperUser(SuperUser)
    setCurrentUserPathPermissions(userPathPermissions)
    setLoading(false)
  }

  useLayoutEffect(() => {
    init()
  }, [])

  const value = {
    currentUser,
    currentUserPathPermissions,
    superUser,
    loggedIn,
  }
  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}
